<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        {{ readonly ? "Просмотр" : "Редактирование" }}
      </template>
      <v-row>
        <v-col cols="12">
          <a-form-model v-model="data" :model="model" :config="{ readonly, dense: true }" :errors="errors" @validate="validate($event)" />
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save v-if="!readonly" @click="submit()"></a-btn-save>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
  </div>
</template>

<script>
import { getAccess, getForm, submitForm, genModel } from "@/components/mixings";

export default {
  components: {},
  mixins: [getAccess, getForm, submitForm, genModel],
  props: {
    dataTable: Array,
    id: Number,
    value: Boolean,
    RO: Array,
    type: { type: Number, default: 0 },
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      m: this.$store.getters["config/get"].models.documentGoodsMoving,
      api: "",
      price_unit: null,
      rowEdit: {},
      rows: [],
      sql: {},
    };
  },
  computed: {
    model() {
      //let model = JSON.parse(JSON.stringify(this.m.formDetail));
      let model = this.getModelList(this.m, "formDetail", true);
      model.forEach(el => {
        if (this.RO.includes(el.name) && this.data[el.name] !== null) {
          el.readonly = true;
        }
      });
      return model;
    },
  },
  created() {
    this.updateData(this.id);
  },
  watch: {
    id(v) {
      if (v) this.row = this.dataTable.find(el => el.id == v);
    },
    value() {
      if (this.value) {
        if (this.id) this.row = this.dataTable.find(el => el.id == this.id);
      }
    },
    "data.amount"() {
      if (!this.loading) this.calcPrice(1);
    },
  },
  methods: {
    afterFetchData() {
      this.loading = false;
    },
    resetForm() {
      this.price_unit = null;
    },
    async calcPrice(v) {
      let q = `
SELECT SUM(amount) as amount, sum(value) as value 
FROM accum_reserve as accum
	where  accum.store_id =${this.data.store_id}
		AND accum.good_id=${this.data.good_id} AND ifnull(accum.object_id,-1) = ${this.data.object_id || -1}
`;
      let price = null;
      try {
        let resp = await this.$axios.post("/accounting/records/report-sql", { q });
        price = resp.data.data[0];
      } catch (error) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка при расчете цены ",
        });
        return;
      }
      if (this.data.amount > price.amount) {
        this.data.amount = price.amount;
        return;
      }
      this.data.value = ((price.value / price.amount) * this.data.amount).toFixed(2);
    },
    async fetchData() {
      this.loading = true;
      this.rows = JSON.parse(JSON.stringify(this.dataTable));
      await this.loadData();
      this.afterFetchData();
    },
    loadData() {
      if (this.id) {
        this.rowEdit = this.rows.find(el => el.id == this.id);
        for (const name in this.rowEdit) {
          this.$set(this.data, name, this.rowEdit[name]);
        }
      }
    },
    post() {
      let rows = [];
      this.rows.forEach(r => {
        if (r.id == this.id) {
          rows.push(this.data);
        } else {
          rows.push(r);
        }
      });
      if (!this.id) {
        rows.push(this.data);
      }
      this.$emit("save", rows);
      this.$emit("input");
    },
  },
};
</script>